import React, { useState } from "react";
import './App.css';
import fetch from "@amzn/sentry-fetch";

const App: React.FC = () => {
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  const [isDeletionConfirmed, setIsDeletionConfirmed] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const apiEndpoint = "https://beta.febtool.kindle.amazon.dev/storeManagerConsent";
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const Spinner = () => {
    return (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
    );
  };

  const handleConsent = async () => {
    try {
      const url = getApprovalUrl();
      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
        credentials: "include"
      });
      if (response.status === 200) {
        setIsConsentGiven(true);
        setIsDeclined(false);
      } else {
        console.log("Failed to grant consent. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      console.log("An error occurred. Please try again later.");
    }
  };

  const handleDecline = async () => {
    try {
      const url = getDeclineUrl();
      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
        credentials: "include"
      });
      if (response.status === 200) {
        setIsDeclined(true);
        setIsConsentGiven(false);
      } else {
        console.log("Failed to Decline. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      console.log("An error occurred. Please try again later.");
    }
  };

  function handlePermission() {
    if (isConsentGiven && !isPermissionGranted) {
      const newWindow = window.open(
          getBindlePermissionUrl() as string,
          '_blank',
          'noopener,noreferrer,width=800,height=600'
      );

      if (!newWindow) {
        console.warn('Popup was blocked');
        return;
      }

      let checkCount = 0;
      const maxChecks = 300; // 5-minute maximum (300 seconds)

      const timer = setInterval(() => {
        checkCount++;

        try {
          if (newWindow.closed) {
            clearInterval(timer);
            setIsPermissionGranted(true);
          }

          // Stop checking after 5 minutes
          if (checkCount >= maxChecks) {
            clearInterval(timer);
            console.warn('Stopped checking after 5 minutes');
          }
        } catch (error) {
          clearInterval(timer);
          console.error('Error checking window status:', error);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }

  const handleClosePopup = () => {
    setIsPopupVisible(false); // Close the pop-up window
  };

  const handleDeletionConfirmation = () => {
    setIsDeletionConfirmed(true);
  };

  const getBindlePermissionUrl = () => {
    const url  = generateApiGwEndpointUrl()
    const bindlePermissionUrl =  url.searchParams.get('bindlePermissionUrl')
    console.log('bindlePermissionUrl', bindlePermissionUrl)

    return bindlePermissionUrl;
  };

  const getApprovalUrl = () => {
    const url = new URL(generateApiGwEndpointUrl().toString());
    url.searchParams.append('isApproved', 'true');
    url.searchParams.delete('bindlePermissionUrl');
    return url.toString();
  };

  const getDeclineUrl = () => {
    const url = new URL(generateApiGwEndpointUrl().toString());
    url.searchParams.append('isApproved', 'false');
    url.searchParams.delete('bindlePermissionUrl');
    return url.toString();
  };

  const getDeletionConfirmUrl = () => {
    return urlParams.get('bindlePermissionUrl');
  };

  const generateApiGwEndpointUrl = () => {

    // Create a new URLSearchParams object for the API endpoint
    const apiParams = new URLSearchParams();

    // Copy all parameters from the current URL to the API params
    urlParams.forEach((value, key) => {
      apiParams.append(key, value);
    });

    const url = new URL(apiEndpoint);
    urlParams.forEach((value, key) => {
      url.searchParams.append(key, value);
    });

    console.log('URL:', url.toString());
    return url
  };

  return (
      <div className="container">
        <h1>Manage Your Resources Permission</h1>

        {isLoading && <Spinner />}

        <div className="step">
          <h2>Provide your Consent for Deletion</h2>
          <p>Click the button below to provide your consent:</p>
          <button
              onClick={async () => {
                setIsLoading(true);
                await handleConsent();
                setIsLoading(false);
                setIsConsentGiven(true);
              }}
              className={`button ${isConsentGiven || isDeclined ? 'disabled' : ''}`}
              disabled={isConsentGiven || isDeclined}
          >
            {isConsentGiven ? 'Consent Granted' : 'Approve'}
          </button>
          <button
              onClick={async () => {
                setIsLoading(true);
                await handleDecline();
                setIsLoading(false);
                setIsDeclined(true);
              }}
              className="button"
              style={{ backgroundColor: '#e74c3c' }}
              disabled={isConsentGiven || isDeclined}
          >
            Decline
          </button>
        </div>

        <div className="step">
          <h2>Grant Permission to the AWS account</h2>
          <p>After completing the Initial Permission, follow these steps:</p>

          <div className="permission-steps">
            <div className="step-item">
              <p>1. Click the button below to open permissions page in a new window:</p>
              <button
                  onClick={async () => {
                    setIsLoading(true);
                    try {
                      window.open(getBindlePermissionUrl() as string, '_blank', 'noopener,noreferrer');
                    } finally {
                      setIsLoading(false);
                    }
                  }}
                  className={`button ${isConsentGiven && !isPermissionGranted ? '' : 'disabled'}`}
                  disabled={!isConsentGiven || isPermissionGranted || isLoading}
              >
                Open Permissions Page
              </button>
            </div>

            <div className="step-item">
              <p>2. After granting permissions in the new window, confirm below:</p>
              <button
                  onClick={() => {
                    const confirmed = window.confirm('Have you completed granting the permissions in the other window?');
                    if (confirmed) {
                      setIsPermissionGranted(true);
                    }
                  }}
                  className={`button ${isConsentGiven && !isPermissionGranted ? '' : 'disabled'}`}
                  disabled={!isConsentGiven || isPermissionGranted}
              >
                Confirm Permissions Granted
              </button>
            </div>
          </div>

          {isPermissionGranted && (
              <div className="success-message">
                <p>✓ Final Permission has been confirmed</p>
              </div>
          )}
        </div>

        <div className="step">
          <h2>Confirm and Trigger Deletion Workflow</h2>
          <p>Click the button below to confirm and trigger the deletion workflow:</p>
          <button
              onClick={handleDeletionConfirmation}
              className={`button ${isPermissionGranted && !isDeletionConfirmed ? '' : 'disabled'}`}
              disabled={!isPermissionGranted || isDeletionConfirmed}
          >
            {isDeletionConfirmed ? 'Deletion workflow started' : 'Confirm Deletion Workflow'}
          </button>
        </div>
      </div>
  );
}

export default App;